import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useMemo } from 'react';
import { WidgetBreadcrumb } from '../../../interfaces/internal';
import { getIconForEquipmentType } from '../../equipment-icons';
import { useOvermindState } from '../../state';
import { SubPage, Tabs } from '../SubPage/SubPage';
import { WidgetProps } from '../Widget/Widget';
import { useTranslation } from 'react-i18next';

interface MyEquipmentOwnProps extends RouteComponentProps {
    widget: WidgetProps;
    children: React.ReactNode;
}

type MyEquipmentProps = MyEquipmentOwnProps;

const handleCloseClick = async () => {
    const splittedPath = window.location.pathname.split('/');
    if (splittedPath.length >= 3) {
        await navigate(`/${splittedPath[1]}`);
    } else {
        await navigate('/');
    }
};

export const MyEquipment: React.FC<MyEquipmentProps> = ({ children, widget }: MyEquipmentProps) => {
    const { t } = useTranslation('MyEquipment');
    const { selectedEquipment, user } = useOvermindState();
    const { pathname } = useLocation();

    const shouldShowEquipmentItemPageTabs = useMemo(() => {
        const equipmentNumberFromPath = pathname.split('/');

        if (
            selectedEquipment &&
            equipmentNumberFromPath[2] === selectedEquipment.equipment_number && // equipmentNumber from path is always at index 2
            user?.access.documentationPage.read
        ) {
            return true;
        } else {
            return false;
        }
    }, [pathname, selectedEquipment, user?.access.documentationPage.read]);

    const breadcrumb: WidgetBreadcrumb = {
        id: widget.id,
        title: widget.title,
        subPage:
            selectedEquipment !== null
                ? {
                      icon: getIconForEquipmentType(selectedEquipment.type),
                      title: selectedEquipment.technical_identificationnumber || selectedEquipment.serial_number,
                  }
                : undefined,
    };

    const selectedEquipmentPageTabs: Tabs[] = [
        {
            name: t('Equipment details'),
            path: `${selectedEquipment?.equipment_number}`,
        },
        {
            name: t('Documentation'),
            path: `${selectedEquipment?.equipment_number}/documentation`,
            isNew: true,
        },
    ];

    return (
        <SubPage
            breadcrumb={breadcrumb}
            onCloseClick={handleCloseClick}
            idSlug={widget.id}
            tabs={shouldShowEquipmentItemPageTabs ? selectedEquipmentPageTabs : []}
        >
            {children}
        </SubPage>
    );
};
