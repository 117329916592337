export default {
    App: {
        'Contact Kalmar': 'Kalmar kontaktieren',
        'Create new': 'Neu erstellen',
        'Dealer community': 'Dealer community',
        'Download failed': 'Download fehlgeschlagen',
        'Failed to get service reports': 'Abrufen der Wartungsberichte fehlgeschlagen',
        'Loading MyKalmar': 'MyKalmar wird geladen',
        'Login again': 'Erneut anmelden',
        'Maintenance reporting': 'Wartungsberichte',
        'Maintenance schedule': 'Wartungsplan',
        'My Cases': 'Meine Fälle',
        'MyKalmar homepage': 'MyKalmar-Homepage',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': 'Navigation zu MyParts fehlgeschlagen',
        'No service reports found': 'Keine Wartungsberichte gefunden',
        'None': 'Keine',
        'Open Dealer community': 'Händler Community öffnen',
        'Open MyKalmar Insight': 'MyKalmar Insight öffnen',
        'Open MyKalmar STORE': 'MyKalmar STORE öffnen',
        'Open used equipment sales portal': 'Gebrauchtgeräte-Verkaufsportal öffnen',
        'Parts order history': 'Bestellhistorie',
        'Kalmar Used Equipment': 'Kalmar Gebrauchtgeräte',
        'Show all ({{ equipmentLength }}) equipment': 'Ihr vollständiges Equipment ({{ equipmentLength }}) anzeigen',
        'Show all Kalmar contacts': 'Alle Kalmar-Kontakte anzeigen',
        'Show all orders in MyParts': 'Alle MyParts Bestellungen anzeigen',
        'Show cases': 'Fälle anzeigen',
        'Show on calendar': 'Im Kalender anzeigen',
        'Try again': 'Versuchen Sie es erneut.',
        'You are now logged out from MyKalmar': 'Sie sind nun von MyKalmar abgemeldet',
    },
    Banner: {
        'Explore Kalmar Services': 'Entdecken Sie die Dienstleistungen von Kalmar',
        'Ensure uptime, optimise your operations, and much more.': 'Halten Sie Ihre Flotte intakt, optimieren Sie Ihre Abläufe und vieles mehr.',
        'Discover how to get the most out of your equipment.': 'Finden Sie heraus, wie Sie Ihre Fahrzeugflotte optimal nutzen können.',
    },
    Common: {
        'Availability': 'Verfügbarkeit',
        'Call the number': 'Rufen Sie die Nummer an',
        'called': 'Angerufen',
        'completed': 'Abgeschlossen',
        'Contracts': 'Verträge',
        'declined': 'Abgelehnt',
        'Maintenance': 'Wartung',
        'Monthly': 'Monatlich',
        'Monthly report': 'Monatlicher Bericht',
        'My Equipment': 'Ihr Equipment',
        'N/A': 'NICHT ZUTREFFEND',
        'Name': 'Name',
        'No data': 'Keine Daten',
        'No data for this time period': 'Keine Daten für diesen Zeitraum',
        'OK': 'OK',
        'ordered': 'Bestellt',
        'Other': 'Sonstige',
        'Other Equipment': 'Sonstige Ausrüstung',
        'Response Time': 'Reaktionszeit',
        'scheduled': 'Geplant',
        'See details': 'Siehe Details',
        'Serial number': 'Seriennummer',
        'Site': 'Standort',
        'Something went wrong': 'Etwas ist schiefgelaufen.',
        'Status': 'Status',
        'today': 'heute',
        'Type': 'Typ',
        'Unknown': 'Unbekannt',
        'Yearly': 'Jährlich',
        'Yearly trend': 'Jährlicher Trend',
    },
    ContactWidget: {
        'Contact us': 'Zögern Sie nicht und kontaktieren Sie uns!',
        'Email': 'E-Mail',
        'Need help?  Or need to know more about our services?': 'Hab Sie Fragen an uns?',
        'Phone': 'Telefon',
        'Send email': 'E-Mail senden',
    },
    CookieConsentBanner: {
        'Learn more': 'Erfahren Sie mehr',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': 'Diese Website verwendet Cookies, um das Surfen zu erleichtern und zusätzliche Funktionen bereitzustellen.',
    },
    CustomerSelector: {
        'No results found with': 'Keine Ergebnisse gefunden mit',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': 'Kunden auswählen',
    },
    DealerCommunity: {
        'Sales & general information': 'Vertrieb & generelle Information',
        'Marketing & products': 'Marketing & Produkte',
        'Technical library': 'Technische Bibliothek',
        'News': 'Neuigkeiten',
        'Kalmar contacts': 'Kalmar Kontakte',
    },
    DocumentationTab: {
        'Manuals': 'Bedienungsanleitungen',
        'Manual': 'Bedienungsanleitungen',
        'Open': 'Offen',
        'Select language': 'Sprache auswählen',
        'See more manuals in other languages': 'Weitere handbücher in anderen sprachen anzeigen',
    },
    EquipmentAvailability: {
        'Equipment Type': 'Ausrüstungstyp',
        'Month': 'Monat',
        'Quarter': 'Quartal',
        'Year': 'Jahr',
    },
    EquipmentAvailabilityByType: {
        'Time spent': 'Aufgewendete Zeit',
    },
    Footer: {
        'General conditions': 'Allgemeine Bedingungen',
        'Kalmar in Social Media': 'Kalmar in den sozialen Medien',
        'Kalmar Digital Service Terms & Conditions': 'Allgemeine Geschäftsbedingungen für den digitalen Service von Kalmar',
        'Privacy policy': 'Datenschutz-Bestimmungen',
    },
    KalmarInsightWidget: {
        'equipment': 'Ausrüstung',
        'Expired license': 'Abgelaufenen Lizenz',
        'License expiring soon': 'Lizenz läuft bald ab',
        'Manage users': 'Benutzerverwaltung',
        'Number of MyKalmar Insight users': 'Benutzeranzahl MyKalmar Insight',
        'Valid license': 'Gültige Lizenz',
    },
    LanguageSelector: {
        'Cancel': 'Abbrechen',
        'Select language': 'Sprache auswählen',
    },
    LoadingFailure: {
        'Loading cases failed': 'Laden der Fälle fehlgeschlagen',
        'Loading equipment availability failed': 'Laden der Ausrüstungsverfügbarkeit fehlgeschlagen',
        'Loading equipment failed': 'Laden der Ausrüstung fehlgeschlagen',
        'Loading failed': 'Laden fehlgeschlagen',
        'Loading maintenance schedule failed': 'Laden des Wartungsplans fehlgeschlagen',
        'Loading preventive maintenance data failed': 'Laden der Daten der vorbeugenden Wartung fehlgeschlagen',
        'Loading response time failed': 'Laden der Reaktionszeit fehlgeschlagen',
        'Loading sales orders failed': 'Laden der Kundenaufträge fehlgeschlagen',
        'Retry': 'Erneut versuchen',
    },
    MaintenanceCalendar: {
        'Equipment name': 'Ausrüstungsname',
        'Maintenance description': 'Beschreibung der Wartung',
        'No maintenance scheduled for the selected day.': 'Keine Wartung für den ausgewählten Tag geplant.',
    },
    MaintenanceReporting: {
        'Equipment Availability': 'Ausrüstungsverfügbarkeit',
        'Preventive Maintenance': 'Vorbeugende Wartung',
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': 'Durchschnittsprozentsatz der Ausrüstungsverfügbarkeit',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': 'Keine bevorstehenden Wartungen',
        'Upcoming maintenances': 'Bevorstehende Wartungen',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or MyKalmar Insight': 'Gehen Sie zu MyKalmar STORE oder MyKalmar Insight',
        'Language': 'Sprache',
        'Log out': 'Abmelden',
        'MyKalmar profile': 'MyKalmar Profil',
        'You can go to MyKalmar STORE and MyKalmar Insight by clicking here': 'Sie können zu MyKalmar STORE und MyKalmar Insight gehen, indem Sie hier klicken',
    },
    MyCasesWidget: {
        'An unknown value': 'Ein unbekannter Wert',
        'caseUpdateFieldChange': '{{fieldName}} wurde von <2>{{oldValue}}</2> zu geändert <4>{{newValue}}</4>',
        'Comment': 'Kommentar',
        'n/a': 'k. A.',
        'New comment': 'Neuer Kommentar',
        'No recent updates to cases': 'Keine kürzlichen Aktualisierungen bei Fällen',
        'Priority': 'Priorität',
        'Recent updates': 'Kürzliche Aktualisierungen',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': 'Verträge',
        'Description': 'Beschreibung',
        'Digital subscriptions': 'Digitale Abonnements',
        'Documentation': 'Dokumentation',
        'Equipment details': 'Ausrüstungsdetails',
        'Equipment name': 'Name Equipment',
        'MyKalmar Insight': 'MyKalmar Insight',
        'Leasing': 'Leasing',
        'Model': 'Modell',
        'Search equipment with name, site, serial number or type': 'Ausrüstung mit Namen, Standort, Seriennummer oder Typ suchen',
        'Search with name, serial number or type': 'Mit Namen, Seriennummer oder Typ suchen',
        'Serial number': 'Seriennummer',
        'Short-term rental': 'Kurzzeitmiete',
        'Show on page': 'Auf der Seite anzeigen',
    },
    MyEquipmentItem: {
        'Contract ends': 'Vertragsende',
        'Contract started': 'Vertragsbeginn',
        'Description': 'Beschreibung',
        'Equipment details': 'Ausrüstungsdetails',
        'insight': 'MyKalmar Insight',
        'leasing': 'Leasing',
        'Machine card': 'Maschinenkarte',
        'maintenance': 'Wartung',
        'Maintenance schedule': 'Wartungsplan',
        'Manufacturing year': 'Herstellungsjahr',
        'Model': 'Modell',
        'No data': 'Keine Daten',
        'No equipment found': 'Keine Ausrüstung gefunden',
        'Open': 'Offen',
        'Order parts': 'Teile bestellen',
        'Owner': 'Besitzer',
        'rental-short-term': 'Kurzzeitmiete',
        'Running hours': 'Betriebsstunden',
        'See Insights': 'Siehe Insights',
        'Serial number': 'Seriennummer',
        'Today': 'Heute',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'At warehouse': 'Im Lager',
        'Customer PO': 'Kundenbestellnummer',
        'Delivered': 'Zugestellt',
        'My last parts orders': 'Meine zuletzt Teilebestellungen',
        'No orders': 'Keine Aufträge',
        'Note: this order will be delivered in multiple shipments.': 'Hinweis: Diese Bestellung wird in mehreren Lieferungen zugestellt.',
        'Order number': 'Bestellnummer',
        'Order received': 'Bestellung erhalten',
        'Processing': 'In Vorbereitung',
        'Shipment': 'Lieferung',
        'Shipped': 'Versendet',
        'Tracking': 'Verfolgung',
    },
    NotFound: {
        'Go to home page': 'Zur Startseite gehen',
        'Page not found': 'Seite nicht gefunden',
        'The page may have been moved or you may not have the necessary rights to access this page': 'Die Seite wurde möglicherweise verschoben oder Sie verfügen möglicherweise nicht über die notwendigen Rechte, um auf diese Seite zuzugreifen.',
    },
    PaginationTable: {
        "itemsCurrentlyShownText": "{{shownItemAmount}} von {{maxItemAmount}} werden angezeigt"
    },
    PreventiveMaintenanceCompletion: {
        'Completed': 'Abgeschlossen',
        'completed in': 'abgeschlossen in',
        'Count': 'Zählung',
        'No data for': 'Keine Daten für',
        'Not completed': 'Nicht abgeschlossen',
        'Percentage': 'Prozentsatz',
        'Planned': 'Geplant',
        'tasks': 'Aufgaben',
    },
    ResponseTime: {
        'Action started': 'Aktion gestartet',
        'avg. response time': 'Durchschn. Reaktionszeit',
        'Call started': 'Anruf gestartet',
        'in': 'in',
        'Operation': 'Betrieb',
        'Response time': 'Reaktionszeit',
    },
    SessionExpirationModal: {
        'Log in again': 'Erneut anmelden',
        'You have been logged out due to inactivity': 'Sie wurden aufgrund von Inaktivität abgemeldet',
        'Your session has expired': 'Ihre Sitzung ist abgelaufen',
    },
    SiteSelector: {
        'Customer': 'Kunde',
        'No sites with equipment': 'Keine Standorte mit Ausrüstung',
        'Select a customer': 'Einen Kunden auswählen',
        'Selected': 'Ausgewählt',
        'Show all sites': 'Alle Standorte anzeigen',
        'Showing all sites': 'Anzeige aller Standorte',
    },
    TimeUnitLinks: {
        'Show': 'Anzeigen',
    },
    Tooltips: {
        'Content': 'Content',
        'Recent reports': 'Recent reports',
    },
};
