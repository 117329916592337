import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '../../hooks';
import { Widget, WidgetProps } from '../Widget/Widget';
import css from './WidgetGrid.module.scss';
import { Banner } from '../Banner/Banner';
import { useOvermindState } from '../../state';

interface WidgetGridProps extends RouteComponentProps {
    widgets: WidgetProps[];
}

export const WidgetGrid: React.FunctionComponent<WidgetGridProps> = ({ widgets }: WidgetGridProps) => {
    const { t } = useTranslation();
    const { isBannerOpen } = useOvermindState();

    useBreadcrumb({
        title: t('Home'),
        key: 'home',
    });

    if (!widgets.length) {
        throw new Error('WidgetGrid should have at least one widget!');
    }
    const remainingWidgets = [...widgets];
    let rows: JSX.Element[] = [];

    while (remainingWidgets.length) {
        const key = Math.ceil(remainingWidgets.length / 2);
        rows = [
            ...rows,
            <div className={css.widgetGridRow} key={`widget-grid-row-${key}`}>
                {remainingWidgets.splice(0, 2).map((widgetProps, index) => (
                    <div key={`${widgetProps.id}-index-${index}`} className={css.widgetGridColumn}>
                        <Widget {...widgetProps} />
                    </div>
                ))}
            </div>,
        ];
    }

    return (
        <div className={css.widgetGrid}>
            <div className={css.welcomeText}>
                <h1>{t('Welcome to MyKalmar')}</h1>
            </div>
            {isBannerOpen && <Banner />}
            {rows}
        </div>
    );
};
