import React from 'react';
import BookIcon from '../../assets/book-icon.svg';
import { useTranslation } from 'react-i18next';

import { Manual } from '../../../interfaces/customer-api';
import css from './ManualItem.module.scss';
import { getLanguageName } from '../../languages';
import OpenInNew from '../../assets/open-in-new.svg';
export interface ManualProps {
    manual: Manual;
}

export const ManualItem: React.FC<ManualProps> = ({ manual }: ManualProps) => {
    const { t } = useTranslation('DocumentationTab');
    return (
        <div className={css.manualContainer} id={manual.name}>
            <div className={css.manualHeader}>
                <BookIcon />
                <div className={css.manualHeaderText}>{manual.type}</div>
                <div className={css.languageTag}>{getLanguageName(manual.ietf_language_code)}</div>
            </div>
            <div className={css.manualBody}>
                <div className={css.manualText}>
                    <p>{manual.description}</p>
                </div>
                <div className={css.openButton}>
                    <a className={css.openButtonText} href={manual.url} target="_blank" rel="noreferrer">
                        {t('Open')}
                        <OpenInNew />
                    </a>
                </div>
            </div>
        </div>
    );
};
