export default {
    App: {
        'Contact Kalmar': '联系 Kalmar',
        'Create new': '新建',
        'Dealer community': 'Dealer community',
        'Download failed': '下载失败',
        'Failed to get service reports': '获取保养报告失败',
        'Loading MyKalmar': '正在加载 MyKalmar',
        'Login again': '再次登录',
        'Maintenance reporting': '维护报告',
        'Maintenance schedule': '维护计划',
        'My Cases': '我的案例',
        'MyKalmar homepage': '我的卡尔玛主页',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': '导航至 MyParts 失败',
        'No service reports found': '未找到保养报告',
        'None': '无',
        'Open Dealer community': ' 打开经销商社区',
        'Open MyKalmar Insight': '打开MyKalmar Insight',
        'Open MyKalmar STORE': '打开 MyKalmar STORE',
        'Open used equipment sales portal': '打开二手设备销售平台',
        'Parts order history': '零件订单历史',
        'Kalmar Used Equipment': '卡尔玛二手设备',
        'Show all ({{ equipmentLength }}) equipment': '显示所有 ({{ equipmentLength }}) 设备',
        'Show all Kalmar contacts': '显示所有 Kalmar 联系人',
        'Show cases': '显示案例',
        'Show on calendar': '在日历上显示',
        'Try again': '重试',
        'You are now logged out from MyKalmar': '您现已登出 MyKalmar',
    },
    Banner: {
        'Explore Kalmar Services': '探索卡尔玛服务',
        'Ensure uptime, optimise your operations, and much more.': '确保正常运行时间、优化您的运营等等。',
        'Discover how to get the most out of your equipment.': '了解如何充分利用您的设备。',
    },
    Common: {
        'Availability': '可用性',
        'Call the number': '拨打号码',
        'called': '已调用',
        'completed': '已完成',
        'Contracts': '合同',
        'declined': '已拒绝',
        'Maintenance': '维护',
        'Monthly': '每月',
        'Monthly report': '月度报告',
        'My Equipment': '我的设备',
        'N/A': '无数据',
        'Name': '名称',
        'No data': '没有数据',
        'No data for this time period': '该时间段无数据',
        'OK': '确定',
        'ordered': '已订购',
        'Other': '其他',
        'Response Time': '响应时间',
        'scheduled': '已计划',
        'See details': '查看详情',
        'Serial number': '序列号',
        'Site': '站点',
        'Something went wrong': '出错',
        'Status': '状态',
        'today': '今天',
        'Type': '类型',
        'Unknown': '未知',
        'Yearly': '每年',
        'Yearly trend': '年度趋势',
    },
    ContactWidget: {
        'Contact us!': '联系我们',
        'Email': '电子邮件',
        'Need help?  Or need to know more about our services?': '需要帮助？或者需要了解更多关于我们的服务',
        'Phone': '电话',
        'Send email': '发送电子邮件',
    },
    CookieConsentBanner: {
        'Learn more': '了解更多',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': '本网站使用 cookie 来增强浏览体验并提供附加功能。',
    },
    CustomerSelector: {
        'No results found with': '未找到结果',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': '选择客户',
    },
    DealerCommunity: {
        'Sales & general information': '销售和一般信息.',
        'Marketing & products': '市场营销和产品.',
        'Technical library': '技术库.',
        'News': '新闻消息.',
        'Kalmar contacts': '卡尔玛联系方式',
    },
    DocumentationTab: {
        'Manuals': '手册',
        'Manual': '手册',
        'Open': '打开',
        'Select language': '选择语言',
        'See more manuals in other languages': '查看更多其他语言版本的手册',
    },
    EquipmentAvailability: {
        'Equipment Type': '设备类型',
        'Month': '月',
        'Quarter': '季度',
        'Year': '年',
    },
    EquipmentAvailabilityByType: {
        'Time spent': '花费的时间',
    },
    Footer: {
        'General conditions': '通用条款',
        'Kalmar Digital Service Terms & Conditions': 'Kalmar 数字服务条款和条件',
        'Privacy policy': '隐私条款',
        'Kalmar in Social Media': '卡尔玛在社交媒体上',
    },
    KalmarInsightWidget: {
        'equipment': '设备',
        'Expired license': '过期许可证',
        'License expiring soon': '即将过期许可证',
        'Manage users': '用户管理',
        'Number of MyKalmar Insight users': 'MyKalmar Insight 用户数',
        'Valid license': '有效许可证',
    },
    LanguageSelector: {
        'Cancel': '取消',
        'Select language': '选择语言',
    },
    LoadingFailure: {
        'Loading cases failed': '加载案例失败',
        'Loading equipment availability failed': '加载设备可用性失败',
        'Loading equipment failed': '加载设备失败',
        'Loading failed': '加载失败',
        'Loading maintenance schedule failed': '加载维护计划失败',
        'Loading preventive maintenance data failed': '加载预防性维护数据失败',
        'Loading response time failed': '加载响应时间失败',
        'Loading sales orders failed': '加载销售订单失败',
        'Retry': '重试',
    },
    MaintenanceCalendar: {
        'Equipment name': '设备名称',
        'Maintenance description': '维护说明',
        'No maintenance scheduled for the selected day.': '选择日期无维护计划。',
    },
    MaintenanceReporting: {
        'Equipment Availability': '设备可用性',
        'Preventive Maintenance': '预防性维护',
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': '设备可用的平均百分比',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': '没有即将到来的维护',
        'Upcoming maintenances': '即将到来的维护',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or MyKalmar Insight': '访问 MyKalmar STORE 或 MyKalmar Insight',
        'Language': '语言',
        'Log out': '登出',
        'MyKalmar profile': 'MyKalmar 个人资料',
        'You can go to MyKalmar STORE and MyKalmar Insight by clicking here': '点击这里可以进入MyKalmar STORE和MyKalmar Insight',
    },
    MyCasesWidget: {
        'An unknown value': '未知数值',
        'caseUpdateFieldChange': '{{fieldName}} 已从 <2>{{oldValue}}</2> 更改为 <4>{{newValue}}</4>',
        'Comment': '备注',
        'n/a': '不适用',
        'New comment': '新备注',
        'No recent updates to cases': '案例无最近更新',
        'Priority': '优先权',
        'Recent updates': '最近更新',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': '合约',
        'Description': '描述',
        'Digital subscriptions': '数字订阅',
        'Documentation': '文档',
        'Equipment details': '设备详情',
        'Equipment name': '设备名称',
        'MyKalmar Insight': 'MyKalmar Insight',
        'Leasing': '租赁',
        'Model': '设备型号',
        'Search equipment with name, site, serial number or type': '按名称、站点、序列号或类型搜索设备',
        'Search with name, serial number or type': '按名称、序列号或类型搜索',
        'Serial number': '序列号',
        'Short-term rental': '短期租赁',
        'Show on page': '在页面上显示',
    },
    MyEquipmentItem: {
        'Contract ends': '合同结束',
        'Contract started': '合同开始',
        'Description': '描述',
        'Equipment details': '设备详情',
        'insight': 'MyKalmar Insight',
        'leasing': '租赁',
        'Machine card': '机卡',
        'maintenance': '维护',
        'Maintenance schedule': '维护计划',
        'Manufacturing year': '制造年份',
        'Model': '设备型号',
        'No data': '没有数据',
        'No equipment found': '未找到设备',
        'Open': '打开',
        'Order parts': '订购零件',
        'Owner': '所有者',
        'rental-short-term': '短期租赁',
        'Running hours': '设备运行小时数',
        'See Insights': '查看洞见',
        'Serial number': '序列号',
        'Today': '今天',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'At warehouse': '仓库中',
        'Customer PO': '客户参考订单号',
        'Delivered': '已送达',
        'My last parts orders': '我最后的零件订单',
        'No orders': '无订单',
        'Note: this order will be delivered in multiple shipments.': '注意：此订单将分多个批次进行配送。',
        'Order number': '订单号',
        'Order received': '订单已接收',
        'Processing': '处理中',
        'Shipment': '货运',
        'Shipped': '已发货',
        'Tracking': '追踪',
    },
    NotFound: {
        'Go to home page': '前往首页',
        'Page not found': '页面未找到',
        'The page may have been moved or you may not have the necessary rights to access this page': '该页面可能已移动或您可能没有访问该页面的必要权限',
    },
    PaginationTable: {
        "itemsCurrentlyShownText": "显示 {{maxItemAmount}} 条中的 {{shownItemAmount}} 条"
    },
    PreventiveMaintenanceCompletion: {
        'Completed': '已完成',
        'completed in': '完成时间',
        'Count': '计数',
        'No data for': '没有数据',
        'Not completed': '未完成',
        'Percentage': '百分比',
        'Planned': '已计划',
        'tasks': '任务',
    },
    ResponseTime: {
        'Action started': '开始的操作',
        'avg. response time': '平均响应时间',
        'Call started': '通话开始',
        'in': 'in',
        'Operation': '操作',
        'Response time': '响应时间',
    },
    SessionExpirationModal: {
        'Log in again': '再次登录',
        'You have been logged out due to inactivity': '您已由于未活动登出',
        'Your session has expired': '您的会话已过期',
    },
    SiteSelector: {
        'Customer': '客户',
        'No sites with equipment': '没有有设备的站点',
        'Select a customer': '选择客户',
        'Selected': '已选择',
        'Show all sites': '显示所有地点',
        'Showing all sites': '显示所有站点',
    },
    TimeUnitLinks: {
        'Show': '显示',
    },
    Tooltips: {
        'Content': 'Content',
        'Recent reports': 'Recent reports',
    },
};
