import css from './DropDown.module.scss';
import React, { useState } from 'react';
import { cns } from '../../utils';
import Chevron from '../../assets/chevron-left.svg';
import { ClickAwayListener } from '@mui/material';

interface Option<T> {
    id: T;
    label: string;
}
interface DropdownProps<T> {
    handleChange: (value: T) => void;
    defaultSelected: T;
    label: string;
    options: Option<T>[];
}

export const Dropdown = <T,>({ handleChange, defaultSelected, options, label }: DropdownProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(defaultSelected);

    const toggleDropdown = () => {
        setIsOpen((previousState) => !previousState);
    };

    const handleSelect = (value: T) => {
        setSelected(value);
        handleChange(value);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <button
                id="container"
                className={cns(css.dropdownContainer, isOpen && css.straightBorderRadius)}
                onClick={toggleDropdown}
            >
                <div className={css.dropdownDisplay}>
                    <span>{label}</span>
                    <span className={css.amount}>{String(selected)}</span>
                </div>
                <div className={cns(css.chevron, isOpen && css.flipped)}>
                    <Chevron />
                </div>
                {isOpen && (
                    <div className={css.dropdownMenu}>
                        {options.map((option) => (
                            <button
                                key={String(option.id)}
                                className={css.dropdownItem}
                                onClick={() => handleSelect(option.id)}
                            >
                                <div className={css.dropdownText}>{option.label}</div>
                            </button>
                        ))}
                    </div>
                )}
            </button>
        </ClickAwayListener>
    );
};
