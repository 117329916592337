import { findIndex } from 'lodash';
import assign from 'lodash/assign';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { Customer } from '../../interfaces/admin-api';
import { API_DATE_FORMAT, MaintenancePlan } from '../../interfaces/customer-api';
import {
    Breadcrumb,
    DateRange,
    KPIParams,
    Language,
    LoadingState,
    LoginState,
    TimeKeyFormat,
} from '../../interfaces/internal';
import { CaseUpdatePayload } from '../../interfaces/realtime-api';
import i18n from '../i18n';
import {
    APIError,
    clearNextURL,
    getIntervals,
    getLanguageCode,
    getLoginURL,
    getSavedCustomerNumber,
    initGoogleTagManager,
    isLoading,
    logger,
    mkEnv,
    navigateToNextURL,
    parseUser,
    removeHash,
    saveAdminStatus,
    saveCustomerNumber,
    setNextURL,
    UserError,
} from '../utils';
import { getCacheKey, maintenancePlanCache } from './effects';
import { Action, AsyncAction } from './index';
import { KPILoadingState, MaintenanceCalendarState, State } from './state';

// Used to identify duplicate plans
const getPlanIdentifier = (plan: MaintenancePlan) =>
    `${plan.date}-${plan.equipment.equipmentNumber}-${plan.description}-${plan.status}`;

const getCaseUpdateIdentifier = (caseUpdate: CaseUpdatePayload) =>
    `${caseUpdate.caseId}-${caseUpdate.changedAt}-${caseUpdate.field}-${caseUpdate.newValue}`;

export const setState: Action<Partial<State>, void> = ({ state }, nextState: Partial<State>) => {
    assign(state, nextState);
};

export const setMaintenanceCalendarState: Action<Partial<MaintenanceCalendarState>, void> = (
    { state },
    nextCalendarState: Partial<MaintenanceCalendarState>,
) => {
    assign(state.maintenanceCalendarState, nextCalendarState);
};

export const setSelectedEquipmentId: Action<string | null, void> = ({ state }, selectedEquipmentId: string | null) => {
    state.selectedEquipmentId = selectedEquipmentId;
};

export const toggleCustomerSelectorModal: Action<void, void> = ({ state }) => {
    state.isCustomerSelectorModalOpen = !state.isCustomerSelectorModalOpen;
};

export const getSites: AsyncAction = async ({ state, effects }) => {
    const { selectedCustomerNumber } = state;
    state.loadingStates.sites = LoadingState.Loading;
    try {
        state.sites = await effects.api.loadSites(selectedCustomerNumber);

        state.loadingStates.sites = LoadingState.Successful;
    } catch (error) {
        state.sites = [];
        state.loadingStates.sites = LoadingState.Failed;
    }
};

export const getEquipment: AsyncAction = async ({ state, effects }) => {
    const { selectedCustomerNumber, userAccess } = state;

    if (!userAccess.equipment.read) {
        return;
    }

    state.loadingStates.equipment = LoadingState.Loading;
    try {
        state.equipment = await effects.api.loadEquipment(selectedCustomerNumber);
        state.loadingStates.equipment = LoadingState.Successful;
    } catch (error) {
        state.equipment = [];
        state.loadingStates.equipment = LoadingState.Failed;
    }
};

export const getCases: AsyncAction = async ({ state, effects, actions }) => {
    const { selectedCustomerNumber, user, userAccess } = state;

    if (!userAccess.cases.read) {
        return;
    }

    state.loadingStates.cases = LoadingState.Loading;
    try {
        state.cases = await effects.api.loadCases(selectedCustomerNumber, user);
        state.loadingStates.cases = LoadingState.Successful;
        actions.streamCaseUpdates();
    } catch (error) {
        state.cases = [];
        state.loadingStates.cases = LoadingState.Failed;
    }
};

export const getInitialMaintenancePlans: AsyncAction = async ({ state, effects }) => {
    const { selectedCustomerNumber, userAccess } = state;

    if (!userAccess.maintenancePlans.read) {
        return;
    }

    state.loadingStates.maintenancePlans = LoadingState.Loading;
    try {
        const startOfThisMonth = DateTime.local().startOf('month').toFormat(API_DATE_FORMAT);
        const maintenancePlans = await effects.api.loadMaintenancePlans(
            { startDate: startOfThisMonth },

            selectedCustomerNumber,
        );
        state.maintenancePlans = sortBy(maintenancePlans, (plan) => plan.date);
        state.loadingStates.maintenancePlans = LoadingState.Successful;
    } catch (error) {
        state.maintenancePlans = [];
        state.loadingStates.maintenancePlans = LoadingState.Failed;
    }
};

export const getMoreMaintenancePlans: AsyncAction<DateRange, void> = async (
    { state, effects },
    dateRange: DateRange,
) => {
    if (!state.userAccess.maintenancePlans.read) {
        return;
    }

    // Check if the plans for this startDate have been fetched already
    // All future maintenance plans are fetched initially so we don't want to re-fetch them
    const isInFuture = DateTime.fromISO(dateRange.startDate).diffNow('days').days > 0;
    if (isInFuture) return;

    const { selectedCustomerNumber, maintenancePlans } = state;
    const cacheKey = getCacheKey(selectedCustomerNumber, dateRange);
    const isCached = maintenancePlanCache.has(cacheKey);

    state.loadingStates.maintenancePlans = LoadingState.Loading;
    try {
        let newPlans: MaintenancePlan[] = [];
        if (isCached) {
            newPlans = maintenancePlanCache.get(cacheKey) as MaintenancePlan[];
        } else {
            newPlans = await effects.api.loadMaintenancePlans(dateRange, selectedCustomerNumber);
        }
        const uniquePlans = uniqBy([...newPlans, ...maintenancePlans], getPlanIdentifier);
        state.maintenancePlans = sortBy(uniquePlans, (plan) => plan.date);
        state.loadingStates.maintenancePlans = LoadingState.Successful;
    } catch (error) {
        state.loadingStates.maintenancePlans = LoadingState.Failed;
    }
};

// Set loading state for every timeUnit in a daterange
const setKPILoadingInRange = (
    params: KPIParams,
    loadingObject: KPILoadingState,
    loadingState: LoadingState,
    keyFormat: TimeKeyFormat = TimeKeyFormat.month,
) => {
    getIntervals(
        DateTime.fromFormat(params.startDate, API_DATE_FORMAT),
        DateTime.fromFormat(params.endDate, API_DATE_FORMAT),
        params.splitBy || 'month',
    ).forEach(({ start }) => {
        loadingObject[start.toFormat(keyFormat)] = loadingState;
    });
};

export const getEquipmentAvailabilityKPI: AsyncAction<KPIParams, void> = async (
    { state, effects },
    params: KPIParams,
) => {
    const { selectedCustomerNumber, userAccess } = state;

    if (!userAccess.maintenanceReporting.read) {
        return;
    }

    const keyFormat = params.keyFormat || TimeKeyFormat.month;
    setKPILoadingInRange(params, state.loadingStates.equipmentAvailabilityKPIs, LoadingState.Loading, keyFormat);
    try {
        const equipmentAvailabilityKPIs = await effects.api.loadEquipmentAvailabilityKPI(
            params,
            selectedCustomerNumber,
        );

        for (const kpi of equipmentAvailabilityKPIs) {
            const kpiKey = DateTime.fromISO(kpi.startTime).toFormat(keyFormat);
            state.equipmentAvailabilityKPIs[kpiKey] = kpi;
        }
        setKPILoadingInRange(params, state.loadingStates.equipmentAvailabilityKPIs, LoadingState.Successful, keyFormat);
    } catch (error) {
        setKPILoadingInRange(params, state.loadingStates.equipmentAvailabilityKPIs, LoadingState.Failed, keyFormat);
    }
};

export const getPMCompletionKPI: AsyncAction<KPIParams, void> = async ({ state, effects }, params: KPIParams) => {
    const { selectedCustomerNumber, userAccess } = state;
    if (!userAccess.maintenanceReporting.read) {
        return;
    }

    setKPILoadingInRange(params, state.loadingStates.PMCompletionKPIs, LoadingState.Loading);
    try {
        const pmCompletionKPIs = await effects.api.loadPMCompletionKPI(params, selectedCustomerNumber);
        for (const kpi of pmCompletionKPIs) {
            const kpiKey = DateTime.fromISO(kpi.startTime).toFormat(TimeKeyFormat.month);
            state.PMCompletionKPIs[kpiKey] = kpi;
        }
        setKPILoadingInRange(params, state.loadingStates.PMCompletionKPIs, LoadingState.Successful);
    } catch (error) {
        setKPILoadingInRange(params, state.loadingStates.PMCompletionKPIs, LoadingState.Failed);
    }
};

export const getResponseTimeKPI: AsyncAction<KPIParams, void> = async ({ state, effects }, params: KPIParams) => {
    const { selectedCustomerNumber, userAccess } = state;

    if (!userAccess.maintenanceReporting.read) {
        return;
    }

    setKPILoadingInRange(params, state.loadingStates.responseTimeKPIs, LoadingState.Loading);
    try {
        const responseTimeKPIs = await effects.api.loadResponseTimeKPI(params, selectedCustomerNumber);
        for (const kpi of responseTimeKPIs) {
            const kpiKey = DateTime.fromISO(kpi.startTime).toFormat(TimeKeyFormat.month);
            state.responseTimeKPIs[kpiKey] = kpi;
        }
        setKPILoadingInRange(params, state.loadingStates.responseTimeKPIs, LoadingState.Successful);
    } catch (error) {
        setKPILoadingInRange(params, state.loadingStates.responseTimeKPIs, LoadingState.Failed);
    }
};

export const getSalesOrders: AsyncAction = async ({ state, effects }) => {
    const { selectedCustomerNumber, userAccess } = state;

    if (!userAccess.salesOrders.read) {
        return;
    }

    state.loadingStates.salesOrders = LoadingState.Loading;
    try {
        const salesOrders = await effects.api.loadSalesOrders(selectedCustomerNumber);
        state.salesOrders = salesOrders;
        state.loadingStates.salesOrders = LoadingState.Successful;

        // TODO: Remove timeout.
        // This is only here because currently there are network problems related to getting the sales orders.
        //state.salesOrders = (await Promise.race([
        //    effects.api.loadSalesOrders(selectedCustomerNumber, selectedSite),
        //    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 30000)),
        //])) as SalesOrder[];
    } catch (error) {
        state.salesOrders = [];
        state.loadingStates.salesOrders = LoadingState.Failed;
    }
};

export const getInitialResources: AsyncAction = async ({ actions, state }) => {
    if (!state.selectedCustomerNumber) return;

    actions.getSites();
    actions.getEquipment();
    actions.getCases();
    actions.getInitialMaintenancePlans();
    actions.getSalesOrders();
    actions.getMaintenanceReportingKPIs();
    actions.getInsightUserCount();
};

export const getInsightUserCount: AsyncAction = async ({ state, effects }) => {
    const { userAccess, selectedCustomerNumber } = state;

    if (!userAccess.kalmarInsight.read || !selectedCustomerNumber) {
        return;
    }

    state.loadingStates.insightUserCount = LoadingState.Loading;
    try {
        state.insightUserCount = await effects.api.loadInsightUsers(selectedCustomerNumber);
        state.loadingStates.insightUserCount = LoadingState.Successful;
    } catch (error) {
        state.insightUserCount = null;
        state.loadingStates.insightUserCount = LoadingState.Failed;
    }
};

export const getMaintenanceReportingKPIs: AsyncAction = async ({ state, actions }) => {
    const { maintenanceReportingSelectedDate, userAccess } = state;

    if (!userAccess.maintenanceReporting.read) {
        return;
    }

    const start = maintenanceReportingSelectedDate.startOf('month');
    const end = maintenanceReportingSelectedDate.endOf('month');

    const selectedMonthParams: KPIParams = {
        startDate: start.toFormat(API_DATE_FORMAT),
        endDate: end.toFormat(API_DATE_FORMAT),
    };

    const selectedMonthKey = start.toFormat(TimeKeyFormat.month);

    // Only load KPIs if we don't already have them or are currently loading them

    if (
        !haveEquipmentAvailabilityKPIsForKey(state, selectedMonthKey) &&
        !isLoading(state.loadingStates.equipmentAvailabilityKPIs[selectedMonthKey])
    ) {
        actions.getEquipmentAvailabilityKPI(selectedMonthParams);
    }
    if (
        !havePMCompletionKPIsForKey(state, selectedMonthKey) &&
        !isLoading(state.loadingStates.PMCompletionKPIs[selectedMonthKey])
    ) {
        actions.getPMCompletionKPI(selectedMonthParams);
    }
    if (
        !haveResponseTimeKPIsForKey(state, selectedMonthKey) &&
        !isLoading(state.loadingStates.responseTimeKPIs[selectedMonthKey])
    ) {
        actions.getResponseTimeKPI(selectedMonthParams);
    }
};

const haveEquipmentAvailabilityKPIsForKey = (state: State, key: string) => {
    const kpi = state.equipmentAvailabilityKPIs[key];
    return kpi && kpi.average !== null;
};

const havePMCompletionKPIsForKey = (state: State, key: string) => {
    const kpi = state.PMCompletionKPIs[key];
    return kpi && Object.keys(kpi.bySite).length !== 0 && Object.keys(kpi.byType).length !== 0;
};

const haveResponseTimeKPIsForKey = (state: State, key: string) => {
    const kpi = state.responseTimeKPIs[key];
    return kpi && kpi.average_minutes !== null;
};

export const handleMaintenanceReportingDateChange: AsyncAction<DateTime, void> = async (
    { state, actions },
    startDate: DateTime,
) => {
    state.maintenanceReportingSelectedDate = startDate;

    actions.getMaintenanceReportingKPIs();
};

export const handleSelectedSiteChange: Action<string | null, void> = ({ state }, selectedSite: string | null) => {
    state.selectedSite = selectedSite;
};

export const handleCustomerChange: Action<Customer, void> = ({ state, actions }, selectedCustomer: Customer) => {
    actions.resetData();

    state.selectedCustomer = selectedCustomer;
    state.selectedCustomerNumber = selectedCustomer.sold_to_number;
    state.isCustomerSelectorModalOpen = false;

    if (state.user?.isAdmin) {
        saveCustomerNumber(state.selectedCustomerNumber);
    }

    actions.getInitialResources();
};

export const resetData: Action = ({ state }) => {
    state.loadingStates.cases = LoadingState.NotLoaded;
    state.loadingStates.equipment = LoadingState.NotLoaded;
    state.loadingStates.maintenancePlans = LoadingState.NotLoaded;
    state.loadingStates.salesOrders = LoadingState.NotLoaded;
    state.loadingStates.deliveries = LoadingState.NotLoaded;
    state.loadingStates.equipmentAvailabilityKPIs = {};
    state.loadingStates.PMCompletionKPIs = {};
    state.loadingStates.responseTimeKPIs = {};
    state.cases = [];
    state.caseUpdates = [];
    state.equipment = [];
    state.equipmentAvailabilityKPIs = {};
    state.maintenancePlans = [];
    state.PMCompletionKPIs = {};
    state.responseTimeKPIs = {};
    state.salesOrders = [];
    state.selectedSite = null;
    state.insightUserCount = null;
};

export const selectPreselectedCustomer: AsyncAction = async ({ actions, effects }) => {
    const savedCustomerNumber = getSavedCustomerNumber();
    const searchParams = queryString.parse(window.location.search);
    const paramsCustomer = searchParams.customerid;

    if (typeof paramsCustomer === 'string') {
        try {
            const customer = await effects.api.getCustomer(paramsCustomer);
            if (customer) {
                actions.handleCustomerChange(customer);
            }
        } catch (error) {
            logger.error('Failed to fetch saved customer:', error);
        }
    } else if (savedCustomerNumber) {
        try {
            const customer = await effects.api.getCustomer(savedCustomerNumber);
            if (customer) {
                actions.handleCustomerChange(customer);
            }
        } catch (error) {
            logger.error('Failed to fetch saved customer:', error);
        }
    }
};

export const handleLogin: AsyncAction = async ({ state, actions, effects }) => {
    setNextURL();

    let { session_user, login_state } = queryString.parse(window.location.hash);

    if (!session_user) {
        try {
            const user = await effects.api.getSessionUser();
            if (user) {
                session_user = JSON.stringify(user);
                login_state = LoginState.Successful;
            }
        } catch (error) {
            // Expected to fail with 401 if there's no session
            if (!(error instanceof APIError && error.statusCode === 401)) {
                logger.error('Error getting session user:', error);
            }
        }
    }

    removeHash();

    switch (login_state) {
        case LoginState.Successful: {
            const sessionUser = parseUser(session_user as string);
            const customerNumbers = sessionUser.equipmentFilter ? Object.keys(sessionUser.equipmentFilter) : [];
            const selectedCustomerNumber = customerNumbers.length ? customerNumbers[0] : null;

            state.loginState = LoginState.Successful;
            state.user = sessionUser;
            state.selectedCustomerNumber = selectedCustomerNumber;

            initGoogleTagManager(mkEnv.gtmId, sessionUser);
            saveAdminStatus(sessionUser.isAdmin);

            if (state.user.isAdmin) {
                if (state.user.email) {
                    const storedLanguage = effects.sideEffects.getLanguage(state.user.email);
                    if (storedLanguage && storedLanguage !== state.language) {
                        actions.setLanguage(storedLanguage);
                    }
                }
                await actions.selectPreselectedCustomer();
            } else {
                const userLanguage = getLanguageCode(state.user.language);
                if (userLanguage && userLanguage !== state.language) {
                    actions.setLanguage(userLanguage);
                }
                actions.getInitialResources();
            }

            navigateToNextURL();
            return;
        }
        case LoginState.Failed: {
            state.loginState = LoginState.Failed;
            return clearNextURL();
        }
        case LoginState.LoggedOut: {
            state.loginState = LoginState.LoggedOut;
            return clearNextURL();
        }
        default:
            window.open(getLoginURL(), '_self');
            break;
    }
};

export const handleLogout: Action = () => {
    window.open(`${mkEnv.apiBaseURL}/api/v1/logout`, '_self');
};

export const closeSession: Action = ({ effects }) => {
    effects.api.closeSession();
};

export const streamCaseUpdates: Action = ({ state, effects }) => {
    if (!state.userAccess.cases.read) {
        return;
    }

    const caseIds = state.cases.map((salesforceCase) => salesforceCase.Id);
    const onPublish = (payload: CaseUpdatePayload) => {
        state.caseUpdates = uniqBy([payload, ...state.caseUpdates], getCaseUpdateIdentifier);
    };

    effects.api.subscribeToCaseUpdates(caseIds, onPublish);
};

export const clearCaseUpdates: Action = ({ state }) => {
    state.caseUpdates = [];
};

export const expireSession: Action = ({ state }) => {
    state.isSessionExpired = true;
};

let toastMessageId = 0;
export const showToastMessage: Action<string, void> = ({ state }, message: string) => {
    state.toastMessages.push({
        id: toastMessageId++,
        timeout: 5000,
        message,
    });
};

export const dismissToastMessage: Action<number, void> = ({ state }, messageId: number) => {
    state.toastMessages = state.toastMessages.filter((m) => m.id !== messageId);
};

export const showErrorToastMessage: AsyncAction<unknown, void> = async ({ actions }, e) => {
    if (e instanceof UserError) {
        actions.showToastMessage(i18n.t(e.message));
    } else {
        actions.showToastMessage(i18n.t('Something went wrong'));
    }
};

export const setLanguage: AsyncAction<Language, void> = async ({ state, effects }, lang) => {
    state.language = lang;
    effects.sideEffects.setLanguage(state.user?.email ?? '', lang);
};

export const saveCookieBannerShown: AsyncAction = async ({ state, effects }) => {
    if (!state.user) {
        // Should never happen
        logger.error('saveCookieBannerShown called without user');
        return;
    }
    state.user.flags.cookieBannerShown = true;
    effects.api.updateUserFlags({ cookieBannerShown: true });
};

export const saveServiceMenuHelperShown: AsyncAction = async ({ state, effects }) => {
    if (!state.user) {
        // Should never happen
        logger.error('saveServiceMenuHelperShown called without user');
        return;
    }
    state.user.flags.serviceMenuHelperShown = true;
    effects.api.updateUserFlags({ serviceMenuHelperShown: true });
};

export const addBreadCrumb: AsyncAction<Breadcrumb, void> = async ({ state }, newCrumb) => {
    const nextCrumbs = [...state.breadcrumbs];

    // If a crumb with the same key exists, we replace it and discard anything after that
    const existingIndex = findIndex(nextCrumbs, (crumb) => crumb.key === newCrumb.key);
    if (existingIndex >= 0) {
        nextCrumbs.splice(existingIndex, nextCrumbs.length, newCrumb);
    } else {
        // Otherwise, just add it to the end
        nextCrumbs.push(newCrumb);
    }

    state.breadcrumbs = nextCrumbs;
};

export const closeBanner: Action<void> = ({ state }) => {
    state.isBannerOpen = false;
}
