import React, { ChangeEvent, useEffect, useState } from 'react';
import { cns } from '../../utils';
import { Spinner } from '../Spinner/Spinner';
import css from './PaginationTable.module.scss';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type Accessor<T> = (row: T) => React.ReactNode;
export interface Column<T> {
    title: string;
    accessor: keyof T | Accessor<T>;
    className?: string;
}

export interface PaginationTableProps<T> {
    columns: Column<T>[];
    data: T[];
    className?: string;
    isLoading?: boolean;
    noRowsMessage?: string;
    onRowClick?: (row: T) => void;
    id?: string;
    rowsPerPage: number;
}

function isFunctionAccessor<T>(accessor: keyof T | Accessor<T>): accessor is Accessor<T> {
    return typeof accessor === 'function';
}

function access<T>(row: T, accessor: keyof T | Accessor<T>) {
    if (isFunctionAccessor(accessor)) {
        return accessor(row);
    }
    return row[accessor] as React.ReactNode;
}

export function PaginationTable<T>({
    columns,
    data,
    className,
    noRowsMessage,
    isLoading,
    onRowClick,
    id,
    rowsPerPage,
}: PaginationTableProps<T>): JSX.Element {
    const { t } = useTranslation('PaginationTable');
    const shouldShowRows = !isLoading && data.length > 0;
    const shouldShowNoRowsMessage = !isLoading && !shouldShowRows && noRowsMessage !== undefined;

    const [page, setPage] = useState(1);

    const handleChangePage = (_event: ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        setPage(1);
    }, [rowsPerPage]);

    const indexOfLastPost = page * rowsPerPage;
    const indexOfFirstPost = (page - 1) * rowsPerPage;
    const itemRange = `${indexOfFirstPost + 1}-${Math.min(indexOfLastPost, data.length)}`;
    const dataAmount = data.length;
    const shownAmountText = t('itemsCurrentlyShownText', {
        shownItemAmount: itemRange,
        maxItemAmount: dataAmount,
    });

    return (
        <>
            <table className={cns(css.table, className)} id={id}>
                <thead>
                    <tr>
                        {columns.map(({ title, className }, colIndex) => (
                            <th key={colIndex} className={cns(className)}>
                                {title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <tr>
                            <td colSpan={columns.length} className={css.spinnerContainer}>
                                <Spinner className={css.spinner} size={5} />
                            </td>
                        </tr>
                    )}
                    {shouldShowRows &&
                        data.slice(indexOfFirstPost, indexOfLastPost).map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                                    {columns.map(({ accessor, className }, colIndex) => (
                                        <td key={colIndex} className={className}>
                                            {access(row, accessor)}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    {shouldShowNoRowsMessage && (
                        <tr>
                            <td colSpan={columns.length} className={css.noRowsMessage}>
                                {noRowsMessage}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className={css.paginationContainer}>
                <Pagination
                    count={Math.ceil(data.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    className={css.pagination}
                    sx={{
                        '.MuiPaginationItem-root': {
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #E9ECEF',
                            fontSize: '1.4rem',
                            borderRadius: '0.4rem',
                            padding: '8px',
                            height: '3.5rem',
                            width: '3.5rem',
                            '&.Mui-selected': {
                                backgroundColor: '#501F52',
                                color: 'white',
                            },
                        },
                    }}
                />
                <div className={css.totalAmountText}>{shownAmountText}</div>
            </div>
        </>
    );
}
