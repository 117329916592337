import React from 'react';
import { Button } from '../Button/Button';
import CloseIcon from '../../assets/close.svg';
import PrintIcon from '../../assets/print.svg';
import KalmarLogo from '../../assets/kalmar-logo-new.svg';
import { ClickAwayListener } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import mustache from 'mustache';
import { DateTime } from 'luxon';
import { useOvermindState } from '../../state';
import { useTranslation } from 'react-i18next';
import { Components } from '../../../interfaces/customer-api';
import css from './MachineCard.module.scss';
import _ from 'lodash';
import { formPdfTemplate, pdfLogoSvg } from './MachineCardPdfStyles';

interface MachineCardWidgetProps {
    onClick: () => void;
}

export const MachineCardWidget: React.FC<MachineCardWidgetProps> = ({ onClick }) => {
    const { t } = useTranslation('DocumentationTab');

    return (
        <div className={css.machineCardWidget}>
            <h3>{t('Machine card')}</h3>
            <div className={css.border}>
                <button onClick={onClick}>{t('Open')}</button>
            </div>
        </div>
    );
};

interface MachineCardProps {
    components: Components[];
    setIsMachineCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MachineCard: React.FC<MachineCardProps> = ({ components, setIsMachineCardOpen }) => {
    const { t } = useTranslation('MyEquipmentItem');
    const { selectedCustomerNumber, selectedEquipment } = useOvermindState();
    if (selectedEquipment?.serial_number == null || selectedCustomerNumber == null) {
        return null;
    }
    const date = DateTime.local().toLocaleString(DateTime.DATE_SHORT);
    const filename = `${selectedEquipment.serial_number}_components.pdf`;

    // Split the components into chunks of 38, since that's the max that can be fitted into one page.
    const componentChunks = _.chunk(
        _.flatten(
            components.map((component) => {
                return component.component_serial_numbers.map((serial_number) => {
                    return { description: component.description, serial_number: serial_number };
                });
            }),
        ),
        38,
    );

    const downloadPdf = async () => {
        // Generating a single template for each chunk of components
        const renderedTemplates = componentChunks.map((component, index) => {
            // Creating data object for mustache
            const comps = {
                components: component,
                date: date,
                selectedEquipment: { serial_number: selectedEquipment.serial_number },
                logo: pdfLogoSvg,
            };
            // Create a dynamic ID for each chunk
            const dynamicId = `machineCardTemplate${index + 1}`;
            const pdfTemplate = formPdfTemplate(dynamicId);
            const renderedTemplate = mustache.render(pdfTemplate, comps);
            return renderedTemplate;
        });

        // Iterate trough the renderedTemplates and creating a page for each template.
        // html2canvas is asynchronous, so need to wait for it to finish before iterating to the next template.
        const pdf = new jsPDF('p', 'pt', 'a4');
        renderedTemplates.forEach(async (templateContent, index) => {
            // Create and append the template content to the document
            const tempTemplate = document.createElement('template');
            tempTemplate.innerHTML = templateContent.trim();
            const fragment = tempTemplate.content;
            document.body.appendChild(fragment);

            const finalPdfTemplate = document.getElementById(`machineCardTemplate${index + 1}`) as HTMLElement;

            if (finalPdfTemplate) {
                try {
                    const canvas = await html2canvas(finalPdfTemplate);
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                    // Add a new page only if it's not the last template
                    if (index < renderedTemplates.length - 1) {
                        pdf.addPage();
                    }
                } finally {
                    // Remove the template from the document after rendering
                    finalPdfTemplate.remove();
                }
            }

            // Save the PDF after all templates have been processed
            if (index === renderedTemplates.length - 1) {
                pdf.save(filename);
            }
        });
    };

    return (
        <div className={css.darkBackground}>
            <ClickAwayListener onClickAway={() => setIsMachineCardOpen(false)}>
                <div className={css.machineCard} id="machine-card">
                    <div className={css.buttonContainer} id="machine-card-topbar">
                        <Button variant="unstyled" onClick={() => downloadPdf()} className={css.printButton}>
                            <PrintIcon />
                        </Button>
                        <Button
                            variant="unstyled"
                            onClick={() => setIsMachineCardOpen(false)}
                            className={css.closeButton}
                        >
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={css.machineCardContent} id="pdf-content">
                        <h3>{t('Machine card')}</h3>
                        <div className={css.title}>
                            <div className={css.leftTitle}>{t('Description')}</div>
                            <div className={css.rightTitle}>{t('Serial Number')}:</div>
                        </div>
                        {components.map((component) => {
                            return component.component_serial_numbers.map((serial_number, index) => {
                                return (
                                    <div className={css.machineCardRow} key={index}>
                                        <span className={css.componentDescription}>{component.description}</span>
                                        <span className={css.serialNumber}>{serial_number}</span>
                                    </div>
                                );
                            });
                        })}
                        <div className={css.machineCardFooter}>
                            <div className={css.componentsDate}>{date}</div>
                            <div className={css.machineName}>{selectedEquipment.serial_number}</div>
                            <div className={css.logoContainer}>
                                <KalmarLogo />
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};
