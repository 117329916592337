import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { MachineCard, MachineCardWidget } from '../MachieCard/MachineCard';
import { useTranslation } from 'react-i18next';
import { ApiCallStatus, useAsyncData } from '../../hooks';
import { useOvermindState, useEffects, useActions } from '../../state';
import css from './DocumentationPage.module.scss';
import { Components, Manual } from '../../../interfaces/customer-api';
import { Spinner } from '../Spinner/Spinner';
import { Manuals } from '../Manuals/Manuals';

interface DocumentationPageProps extends RouteComponentProps {
    equipmentId?: string;
}

export const DocumentationPage: React.FunctionComponent<DocumentationPageProps> = (props: DocumentationPageProps) => {
    const { equipmentId } = props;

    const [isMachineCardOpen, setIsMachineCardOpen] = useState(false);

    const { t } = useTranslation('Common');
    const { api } = useEffects();
    const { setSelectedEquipmentId } = useActions();
    const { selectedCustomerNumber, selectedEquipment } = useOvermindState();

    useEffect(() => {
        if (equipmentId) {
            setSelectedEquipmentId(equipmentId);
        }
        return () => {
            // Reset on unmount
            setSelectedEquipmentId(null);
        };
    }, [equipmentId, setSelectedEquipmentId]);

    const loadManuals = () => api.loadManuals(selectedCustomerNumber, selectedEquipment?.equipment_number || '');
    const [manuals, isManualsLoading, apiCallStatus] = useAsyncData<Manual[]>(
        loadManuals,
        [],
        [selectedEquipment?.serial_number, selectedCustomerNumber],
    );

    const loadComponents = () => api.loadComponents(selectedCustomerNumber, selectedEquipment?.serial_number || '');
    const [components, isComponentsLoading] = useAsyncData<Components[]>(
        loadComponents,
        [],
        [selectedEquipment?.serial_number, selectedCustomerNumber],
    );

    const openMachineCard = () => {
        setIsMachineCardOpen((previousState) => !previousState);
    };

    const isDataLoading = isComponentsLoading || isManualsLoading;
    const dataExist = manuals.length > 0 || components.length > 0;

    return (
        <div id='documentation-tab'>
            <div className={css.documentationTabItem}>
                {isDataLoading && (
                    <div className={css.centered}>
                        <Spinner size={3} />
                    </div>
                )}
                {!isDataLoading && dataExist && (
                    <div className={css.columns}>
                        <div className={css.column}>{manuals.length > 0 && <Manuals manuals={manuals} />}</div>
                        <div className={css.column}>
                            {components.length > 0 && <MachineCardWidget onClick={openMachineCard} />}
                            {isMachineCardOpen && (
                                <MachineCard components={components} setIsMachineCardOpen={setIsMachineCardOpen} />
                            )}
                        </div>
                    </div>
                )}
                {
                    !isDataLoading &&
                    apiCallStatus === ApiCallStatus.INTERNAL_SERVER_ERROR &&
                    <div className={css.centered}>{t('Something went wrong')}</div>
                }
                {
                    !isDataLoading &&
                    !dataExist &&
                    apiCallStatus === ApiCallStatus.SUCCESS &&
                    <div className={css.centered}>{t('No data')}</div>
                }
            </div>
        </div>
    );
};
