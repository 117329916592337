import { DateTime } from 'luxon';
import { SAPEquipment, SAPServiceContract } from './sap-rd';

export const API_DATE_FORMAT = 'yyyyMMdd';

export type ContractType = 'insight' | 'leasing' | 'maintenance' | 'rental-short-term' | 'on-demand-maintenance';

export type ProfitCenter =
    | 'ECH'
    | 'FLH'
    | 'FLL'
    | 'FLM'
    | 'FLT98'
    | 'HTT'
    | 'ISS05'
    | 'MTT'
    | 'RSS'
    | 'RTG'
    | 'SER04_R'
    | 'SER06'
    | 'SER07';

export enum MaintenanceStatus {
    CALLED = 'called',
    COMPLETED = 'completed',
    DECLINED = 'declined',
    ORDERED = 'ordered',
    SCHEDULED = 'scheduled',
}

export interface EquipmentContract {
    contract: SAPServiceContract;
    type: ContractType;
}

export interface Equipment extends SAPEquipment {
    type: string | null;
    contracts: EquipmentContract[];
    contractTypeSet: ContractType[];
    profitCenter: string;
}

export interface PreventiveMaintenanceCompletionKPINumbers {
    planned: number;
    completed: number;
    percentage: number;
}

export interface PreventiveMaintenanceCompletionKPI {
    all: PreventiveMaintenanceCompletionKPINumbers;
    byType: {
        [key: string]: PreventiveMaintenanceCompletionKPINumbers;
    };
    bySite: {
        [key: string]: {
            all: PreventiveMaintenanceCompletionKPINumbers;
            byType: {
                [key: string]: PreventiveMaintenanceCompletionKPINumbers;
            };
        };
    };
    startTime: string;
    endTime: string;
}

export interface EquipmentAvailabilityByEquipment {
    [key: string]: {
        average: number;
        totalDownTime: number;
    };
}

export interface EquipmentAvailabilityByType {
    [key: string]: {
        average: number | null;
        byEquipment: EquipmentAvailabilityByEquipment;
    };
}

export interface EquipmentAvailabilityKPI {
    average: number | null;
    bySite: {
        [key: string]: {
            average: number | null;
            byType: EquipmentAvailabilityByType | null;
        };
    } | null;
    byType: EquipmentAvailabilityByType | null;
    startTime: string;
    endTime: string;
}

export interface ResponseTimeKPICallout {
    malfunction_start: string;
    minutes: number;
    operation_description: string;
    operation_start: string;
}

export interface ResponseTimeKPI {
    average_minutes: number | null;
    callouts: ResponseTimeKPICallout[] | null;
    bySite: {
        [key: string]: {
            average_minutes: number | null;
            callouts: ResponseTimeKPICallout[] | null;
        };
    } | null;
    startTime: string;
    endTime: string;
}

export interface MaintenancePlan {
    siteNumber: string;
    equipment: {
        equipmentNumber: string;
        serialNumber: string;
        technicalIdentificationNumber: string | null;
    };
    description: string;
    date: string;
    status: MaintenanceStatus;
    serviceOrderNumber: string | null;
    hasServiceReport: boolean;
    isUnplanned: boolean;
}
export interface Components {
    description: string;
    component_serial_numbers: string[];
}
export interface SalesforceUser {
    ContactId: string | null;
}

export interface SalesforceCase {
    Id: string;
    CaseNumber: string;
    Subject: string;
}

export interface Site {
    name: string;
    city: string;
    country: string | null;
    country_code: string | null;
    postal_code: string;
    ship_to_number: string;
    street_address: string;
}

export interface Contact {
    countryName: string;
    email: string;
    phone: string;
}

export type SalesOrderStatus = 'NOT CONFIRMED' | 'OPEN' | 'IN WAREHOUSE PROCESS' | 'SHIPPED' | 'DELIVERED';

export type ShipmentTrackingLink = {
    code: string;
    url: string;
};

export type SalesOrdersRow = {
    date: string | null;
    estimatedDeliveryDate: string;
    proofOfDeliveryDate: string;
    status: string;
    trackingUrl: string;
};
export interface SalesOrder {
    documentDate: string;
    globalOrderNumber: string;
    purchaseOrderNumber: string;
    rows: SalesOrdersRow[];
    status: SalesOrderStatus;
}

export interface RunningHours {
    runningHours: number | undefined;
}

export interface Manual {
    name: string,
    type: string,
    description: string,
    file_size: number,
    file_name: string,
    url: string,
    url_expiry_time: DateTime,
    ietf_language_code: string,
    iso_language_code: string
}