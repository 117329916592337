import { useTranslation } from 'react-i18next';
import { Manual } from '../../../interfaces/customer-api';
import React, { useEffect, useMemo, useState } from 'react';
import css from './Manuals.module.scss';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useOvermindState } from '../../state';
import { getLanguageName, getDefaultLanguageCode } from '../../languages';
import { ManualItem } from './ManualItem';

interface ManualsProps {
    manuals: Manual[];
}
interface ManualLanguage {
    code: string;
    name: string | undefined;
    manualsCount: number;
}
interface ManualsPerLanguage {
    [key: string]: {
        manualCount: number;
        code: string;
        name: string | undefined;
        manuals: Manual[];
    };
}

export const Manuals: React.FC<ManualsProps> = ({ manuals }: ManualsProps) => {
    const { t } = useTranslation('DocumentationTab');
    const { language } = useOvermindState();
    const [selectedLanguage, setSelectedLanguage] = useState({
        code: language as string,
        name: getLanguageName(language as string),
    });
    const defaultLanguageCode: string = getDefaultLanguageCode();

    const manualsPerLanguage: ManualsPerLanguage = useMemo(() => {
        const languageToManuals = manuals.reduce((acc, manual) => {
            acc[manual.ietf_language_code] = acc[manual.ietf_language_code] || {
                manualCount: 0,
                code: manual.ietf_language_code,
                name: getLanguageName(manual.ietf_language_code),
                manuals: [],
            };
            acc[manual.ietf_language_code].manualCount++;
            acc[manual.ietf_language_code].manuals.push(manual);
            return acc;
        }, {} as ManualsPerLanguage);

        // sort the manuals by type
        Object.entries(languageToManuals).forEach(([, data]) => {
            data.manuals = data.manuals.sort((manualA, manualB) => manualA.type.localeCompare(manualB.type));
        });

        return languageToManuals;
    }, [manuals]);

    const manualLanguages: ManualLanguage[] = useMemo(() => {
        const languageCodes = Array.from(new Set(manuals.map((manual) => manual.ietf_language_code)));
        return languageCodes
            .map((code) => {
                return {
                    code: code,
                    name: getLanguageName(code),
                    manualsCount: manualsPerLanguage[code].manualCount || 0,
                };
            })
            .sort((languageA, languageB) => languageA.name?.localeCompare(languageB.name || '') || 0);
    }, [manuals, manualsPerLanguage]);

    const maxManualsLanguage: ManualLanguage = useMemo(() => {
        const sorted = [...manualLanguages].sort(
            (languageA, languageB) => languageB.manualsCount - languageA.manualsCount,
        );
        return {
            code: sorted[0].code,
            name: sorted[0].name,
            manualsCount: sorted[0].manualsCount,
        };
    }, [manualLanguages]);

    useEffect(() => {
        if (manualsPerLanguage[language]) {
            setSelectedLanguage({
                code: language as string,
                name: getLanguageName(language as string),
            });
        } else {
            setSelectedLanguage({
                code: defaultLanguageCode,
                name: getLanguageName(defaultLanguageCode),
            });
        }
    }, [defaultLanguageCode, language, manualsPerLanguage]);

    const renderMoreManuals = () => {
        let manuals = manualsPerLanguage[defaultLanguageCode].manuals;
        if (maxManualsLanguage && maxManualsLanguage.code !== defaultLanguageCode) {
            manuals = manuals.concat(manualsPerLanguage[maxManualsLanguage.code].manuals);
        }

        const omitManualTypes = manualsPerLanguage[selectedLanguage.code].manuals.map((manual) => manual.type);
        manuals = manuals.filter((manual) => !omitManualTypes.includes(manual.type));

        if (manuals.length > 0) {
            return (
                <div>
                    <div className={css.label}>{t('See more manuals in other languages')}</div>
                    {manuals.map((manual) => (
                        <ManualItem key={`${manual.file_name}-${manual.type}`} manual={manual} />
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div className={css.manualWidget} id="manuals-list">
            <h3>{t('Manuals')}</h3>
            <div className={css.manualLanguageSelector}>
                <InputLabel id="language-select-label" className={css.dropDownMenuLabel}>
                    {t('Select language')}
                </InputLabel>
                <FormControl>
                    <Select
                        labelId="language-select-label"
                        id="language-select"
                        value={selectedLanguage.code}
                        onChange={(event: SelectChangeEvent) => {
                            setSelectedLanguage({
                                code: event.target.value,
                                name: getLanguageName(event.target.value),
                            });
                        }}
                        className={css.dropDownMenu}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 500, // Adjust height as needed
                                    overflowY: 'auto',
                                },
                            },
                        }}
                    >
                        {manualLanguages.map((manualLanguage) => (
                            <MenuItem
                                sx={{
                                    fontSize: '12px',
                                    whiteSpace: 'normal',
                                }}
                                key={manualLanguage.name}
                                value={manualLanguage.code}
                            >
                                {`${manualLanguage.name} (${manualLanguage.manualsCount} ${
                                    manualLanguage.manualsCount && manualLanguage.manualsCount > 1
                                        ? t('Manuals').toLowerCase()
                                        : t('Manual').toLowerCase()
                                })`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {manualsPerLanguage[selectedLanguage.code] &&
                manualsPerLanguage[selectedLanguage.code].manuals.map((manual) => {
                    return <ManualItem key={`${manual.file_name}-${manual.type}`} manual={manual} />;
                })}
            {maxManualsLanguage &&
                manualsPerLanguage[selectedLanguage.code].manuals.length < maxManualsLanguage.manualsCount && (
                    <div>{renderMoreManuals()}</div>
                )}
        </div>
    );
};
