import debug, { Debugger } from 'debug';

const prefix = 'mykalmar';

export function debugLogger(scope: string): Debugger {
    const logger = debug(`${prefix}:${scope}`);
    // eslint-disable-next-line no-console
    logger.log = console.log.bind(console);
    return logger;
}

export function errorLogger(scope: string): Debugger {
    return debug(`${prefix}:${scope}:error`);
}
