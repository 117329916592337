import React from 'react';
import { BREAKPOINT_LARGE } from '../../config';
import { useActions } from '../../state';
import css from './Banner.module.scss';
import { useMediaQuery } from '@mui/material';
import BondDoubleExtendedIcon from '../../assets/bond-double extended-grape-solid.svg';
import BondDoubleIcon from '../../assets/bond-double-purple-solid.svg';
import BondSingeIcon from '../../assets/bond-single-sand-solid.svg';
import CloseIcon from '../../assets/close.svg';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import BannerPhoto from '../../assets/service-brochure-cover-thumbnail.png';
import CarouselIcon from '../../assets/carousel-icon.svg';
import { getMarketingBannerLink } from '../../utils';

export const Banner = (): JSX.Element => {
    const { t } = useTranslation('Banner');
    const isMobile = useMediaQuery(BREAKPOINT_LARGE);
    const { closeBanner } = useActions();

    return (
        <>
            {isMobile ? (
                <div className={css.mobileBannerContainer}>
                    <Button variant="unstyled" onClick={closeBanner} className={css.closeButton}>
                        <CloseIcon />
                    </Button>
                    <div className={css.mobileBannerContentContainer}>
                        <div className={css.mobileBannerHeadText}>{t('Explore Kalmar Services')}</div>
                        <div className={css.mobileBannerText}>
                            <p>{t('Ensure uptime, optimise your operations, and much more.')}</p>
                            <p>{t('Discover how to get the most out of your equipment. ')}</p>
                        </div>
                        <a className={css.openButton} href={getMarketingBannerLink()} target="_blank" rel="noreferrer">
                            <CarouselIcon />
                            {t('Open PDF')}
                        </a>
                    </div>
                    <div className={css.mobileBondsContainer}>
                        <div className={css.mobileExtendedBond}>
                            <BondDoubleExtendedIcon />
                        </div>
                        <div className={css.mobileDoubleBond}>
                            <BondDoubleIcon />
                        </div>
                        <div className={css.mobileSingleBond}>
                            <BondSingeIcon />
                        </div>
                    </div>
                </div>
            ) : (
                // Is not mobile
                <div className={css.bannerContainer}>
                    <Button variant="unstyled" onClick={closeBanner} className={css.closeButton}>
                        <CloseIcon />
                    </Button>
                    <div className={css.bannerImage}>
                        <img src={BannerPhoto} alt="Banner image" />
                    </div>
                    <div className={css.bannerContentContainer}>
                        <div className={css.bannerHeadText}>{t('Explore Kalmar Services')}</div>
                        <div className={css.bannerText}>
                            <p>{t('Ensure uptime, optimise your operations, and much more.')}</p>
                            <p>{t('Discover how to get the most out of your equipment.')}</p>
                        </div>
                        <a className={css.openButton} href={getMarketingBannerLink()} target="_blank" rel="noreferrer">
                            <CarouselIcon />
                            {t('Open PDF')}
                        </a>
                    </div>
                    <div className={css.bondsContainer}>
                        <div className={css.extendedBond}>
                            <BondDoubleExtendedIcon />
                        </div>
                        <div className={css.doubleBond}>
                            <BondDoubleIcon />
                        </div>
                        <div className={css.singleBond}>
                            <BondSingeIcon />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
