import { Router, RouteComponentProps, Redirect } from '@reach/router';
import React from 'react';
import { EquipmentAvailability } from '../EquipmentAvailability/EquipmentAvailability';
import { EquipmentAvailabilityByType } from '../EquipmentAvailabilityByType/EquipmentAvailabilityByType';
import { MaintenanceReporting } from '../MaintenanceReporting/MaintenanceReporting';
import { MaintenanceSchedule } from '../MaintenanceSchedule/MaintenanceSchedule';
import { MyEquipment } from '../MyEquipment/MyEquipment';
import { MyEquipmentItem } from '../MyEquipmentItem/MyEquipmentItem';
import { MyEquipmentTable } from '../MyEquipmentTable/MyEquipmentTable';
import { NotFound } from '../NotFound/NotFound';
import { PreventiveMaintenanceCompletion } from '../PreventiveMaintenanceCompletion/PreventiveMaintenanceCompletion';
import { MonthlyPMCompletion } from '../PreventiveMaintenanceCompletion/MonthlyPMCompletion/MonthlyPMCompletion';
import { ResponseTime } from '../ResponseTime/ResponseTime';
import { MonthlyResponseTime } from '../ResponseTime/MonthlyResponseTime/MonthlyResponseTime';
import { YearlyResponseTime } from '../ResponseTime/YearlyResponseTime/YearlyResponseTime';
import { WidgetProps } from '../Widget/Widget';
import { YearlyPMCompletion } from '../PreventiveMaintenanceCompletion/YearlyPMCompletion/YearlyPMCompletion';
import { DocumentationPage } from '../DocumentationTab/DocumentationPage';

interface WidgetRouterProps extends RouteComponentProps {
    widgets: WidgetProps[];
}

const getRouteForWidget = (widget: WidgetProps) => {
    const routeProps = {
        key: widget.id,
        path: `/${widget.id}`,
        widget,
    };

    switch (widget.id) {
        case 'maintenance-reporting': {
            return (
                <MaintenanceReporting {...routeProps}>
                    <EquipmentAvailability path="equipment-availability/:timeUnit" />
                    <EquipmentAvailabilityByType path="equipment-availability/:timeUnit/:equipmentType" />
                    <Redirect from="equipment-availability" to="month" noThrow />
                    <PreventiveMaintenanceCompletion path="preventive-maintenance">
                        <MonthlyPMCompletion path="monthly" />
                        <YearlyPMCompletion path="yearly" />
                        <Redirect from="/" to="monthly" noThrow />
                    </PreventiveMaintenanceCompletion>
                    <ResponseTime path="response-time">
                        <MonthlyResponseTime path="monthly" />
                        <YearlyResponseTime path="yearly" />
                        <Redirect from="/" to="monthly" noThrow />
                    </ResponseTime>
                </MaintenanceReporting>
            );
        }
        case 'maintenance-schedule':
            return <MaintenanceSchedule {...routeProps} />;
        case 'my-equipment':
            return (
                <MyEquipment {...routeProps}>
                    <MyEquipmentTable path="/" />
                    <MyEquipmentItem path=":equipmentId" />
                    <DocumentationPage path=":equipmentId/documentation" />
                </MyEquipment>
            );
        default:
            return null;
    }
};

export const WidgetRouter: React.FunctionComponent<WidgetRouterProps> = ({ widgets }: WidgetRouterProps) => {
    const renderedRoutes = widgets.map((widget) => getRouteForWidget(widget)).filter((component) => component !== null);

    return (
        <Router>
            {renderedRoutes}
            <NotFound default />
        </Router>
    );
};
