export default {
    App: {
        'Automation support': 'Automation support',
        'Contact Kalmar': 'Contact Kalmar',
        'Create new': 'Create new',
        'Dealer community': 'Dealer community',
        'Download failed': 'Download failed',
        'Failed to get service reports': 'Failed to get service reports',
        'home': 'home',
        'Loading MyKalmar': 'Loading MyKalmar',
        'Login again': 'Login again',
        'Maintenance reporting': 'Maintenance reporting',
        'Maintenance schedule': 'Maintenance schedule',
        'My Cases': 'My Cases',
        'MyKalmar homepage': 'MyKalmar homepage',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': 'Navigating to MyParts failed',
        'No service reports found': 'No service reports found',
        'None': 'None',
        'Open Dealer community': 'Open Dealer community',
        'Open MyKalmar STORE': 'Open MyKalmar STORE',
        'Open MyKalmar Insight': 'Open MyKalmar Insight',
        'Open used equipment sales portal': 'Open used equipment sales portal',
        'Show all orders in MyParts': 'Show all orders in MyParts',
        'Parts order history': 'Parts order history',
        'Kalmar Used Equipment': 'Kalmar Used Equipment',
        'Show all ({{ equipmentLength }}) equipment': 'Show all ({{ equipmentLength }}) equipment',
        'Show all deliveries ({{ deliveryCount }})': 'Show all deliveries ({{ deliveryCount }})',
        'Show all Kalmar contacts': 'Show all Kalmar contacts',
        'Show cases': 'Show cases',
        'Show on calendar': 'Show on calendar',
        'Try again': 'Try again',
        'You are now logged out from MyKalmar': 'You are now logged out from MyKalmar',
    },
    Banner: {
        'Explore Kalmar Services': 'Explore Kalmar Services',
        'Ensure uptime, optimise your operations, and much more.': 'Ensure uptime, optimise your operations, and much more.',
        'Discover how to get the most out of your equipment.': 'Discover how to get the most out of your equipment.',
    },
    Common: {
        'Availability': 'Availability',
        'Call the number': 'Call the number',
        'called': 'Called',
        'completed': 'Completed',
        'Contracts': 'Contracts',
        'declined': 'Declined',
        'Email': 'Email',
        'Home': 'Home',
        'Maintenance': 'Maintenance',
        'Monthly report': 'Monthly report',
        'Monthly': 'Monthly',
        'My Delivery': 'My Delivery',
        'My Equipment': 'My Equipment',
        'N/A': 'N/A',
        'Name': 'Name',
        'No data for this time period': 'No data for this time period',
        'No data': 'No data',
        'OK': 'OK',
        'ordered': 'Ordered',
        'Other': 'Other',
        'Other Equipment': 'Other Equipment',
        'Phone': 'Phone',
        'Response Time': 'Response Time',
        'scheduled': 'Scheduled',
        'Search': 'Search',
        'See details': 'See details',
        'Serial number': 'Serial number',
        'Site': 'Site',
        'Something went wrong': 'Something went wrong',
        'Status': 'Status',
        'today': 'today',
        'Type': 'Type',
        'Unknown': 'Unknown',
        'Yearly trend': 'Yearly trend',
        'Yearly': 'Yearly',
        'Welcome to MyKalmar': 'Welcome to MyKalmar',
    },
    ContactWidget: {
        'Call the number': 'Call the number',
        'Contact us!': 'Contact us!',
        'Need help?  Or need to know more about our services?': 'Need help?  Or need to know more about our services?',
        'Send email': 'Send email',
    },
    CookieConsentBanner: {
        'Learn more': 'Learn more',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': 'This website makes use of cookies to enhance browsing experience and provide additional functionality.',
    },
    CustomerSelector: {
        'No results found with': 'No results found with',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': 'Select customer',
    },
    DealerCommunity: {
        'Sales & general information': 'Sales & general information',
        'Marketing & products': 'Marketing & products',
        'Technical library': 'Technical library',
        'News': 'News',
        'Kalmar contacts': 'Kalmar contacts',
    },
    DocumentationTab: {
        'Manuals': 'Manuals',
        'Manual': 'Manual',
        'Open': 'Open',
        'Select language': 'Select language',
        'See more manuals in other languages': 'See more manuals in other languages',
    },
    EquipmentAvailability: {
        'Equipment type': 'Equipment type',
        'Month': 'Month',
        'No data for this time period': 'No data for this time period',
        'Quarter': 'Quarter',
        'See details': 'See details',
        'Year': 'Year',
    },
    EquipmentAvailabilityByType: {
        'No data for this time period': 'No data for this time period',
        'Time spent': 'Time spent',
    },
    Footer: {
        'General conditions': 'General conditions',
        'MyKalmar Insight': 'MyKalmar Insight',
        'Invoicing instructions': 'Invoicing instructions',
        'Kalmar in Social Media': 'Kalmar in Social Media',
        'Legal notice': 'Legal notice',
        'MyKalmar': 'MyKalmar',
        'MyKalmar STORE': 'MyKalmar STORE',
        'Postal address': 'Postal address',
        'Privacy policy': 'Privacy policy',
        'Sitemap': 'Sitemap',
        'Supplier pages': 'Supplier pages',
        'Street address': 'Street address',
        'Subscribe': 'Subscribe',
    },
    KalmarInsightWidget: {
        'equipment': 'equipment',
        'Expired license': 'Expired license',
        'License expiring soon': 'License expiring soon',
        'Manage users': 'Manage users',
        'Number of MyKalmar Insight users': 'Number of MyKalmar Insight users',
        'Valid license': 'Valid license',
    },
    LanguageSelector: {
        'Cancel': 'Cancel',
        'Select language': 'Select language',
    },
    LoadingFailure: {
        'Loading cases failed': 'Loading cases failed',
        'Loading deliveries failed': 'Loading deliveries failed',
        'Loading equipment availability failed': 'Loading equipment availability failed',
        'Loading equipment failed': 'Loading equipment failed',
        'Loading failed': 'Loading failed',
        'Loading maintenance schedule failed': 'Loading maintenance schedule failed',
        'Loading preventive maintenance data failed': 'Loading preventive maintenance data failed',
        'Loading response time failed': 'Loading response time failed',
        'Loading sales orders failed': 'Loading sales orders failed',
        'Retry': 'Retry',
    },
    MaintenanceCalendar: {
        'Equipment name': 'Equipment name',
        'Maintenance description': 'Maintenance description',
        'No maintenance scheduled for the selected day.': 'No maintenance scheduled for the selected day.',
    },
    MaintenanceReporting: {
        'Equipment availability': 'Equipment availability',
        'Preventive maintenance': 'Preventive maintenance',
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': 'Average percentage the equipment was available',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': 'No upcoming maintenances',
        'Upcoming maintenances': 'Upcoming maintenances',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or MyKalmar Insight': 'Go to MyKalmar STORE or MyKalmar Insight',
        'Language': 'Language',
        'Log out': 'Log out',
        'MyKalmar profile': 'MyKalmar profile',
        'You can go to MyKalmar STORE and MyKalmar Insight by clicking here': 'You can go to MyKalmar STORE and MyKalmar Insight by clicking here',
    },
    MyCasesWidget: {
        'An unknown value': 'An unknown value',
        'caseUpdateFieldChange': '{{fieldName}} changed from <2>{{oldValue}}</2> to <4>{{newValue}}</4>',
        'Comment': 'Comment',
        'n/a': 'n/a',
        'New comment': 'New comment',
        'No recent updates to cases': 'No recent updates to cases',
        'Priority': 'Priority',
        'Recent updates': 'Recent updates',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': 'Contracts',
        'Description': 'Description',
        'Documentation': 'Documentation',
        'Digital subscriptions': 'Digital subscriptions',
        'Equipment details': 'Equipment details',
        'Equipment name': 'Equipment name',
        'MyKalmar Insight': 'MyKalmar Insight',
        'Leasing': 'Leasing',
        'Model': 'Model',
        'Search equipment with name, serial number, description, model or site': 'Search equipment with name, serial number, description, model or site',
        'Search with name, serial number or description': 'Search with name, serial number or type',
        'Serial number': 'Serial number',
        'Short-term rental': 'Short-term rental',
        'Show on page': 'Show on page',
    },
    MyEquipmentItem: {
        'Contract ends': 'Contract ends',
        'Contract started': 'Contract started',
        'Description': 'Description',
        'Equipment details': 'Equipment details',
        'insight': 'MyKalmar Insight',
        'leasing': 'Leasing',
        'Machine card': 'Machine card',
        'maintenance': 'Maintenance',
        'Maintenance schedule': 'Maintenance schedule',
        'Manufacturing year': 'Manufacturing year',
        'Model': 'Model',
        'No data': 'No data',
        'No equipment found': 'No equipment found',
        'Open': 'Open',
        'Order parts': 'Order parts',
        'Owner': 'Owner',
        'rental-short-term': 'Short-term rental',
        'Running hours': 'Running hours',
        'See Insights': 'See Insights',
        'Serial Number': 'Serial Number',
        'Today': 'Today',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'At warehouse': 'At warehouse',
        'Customer PO': 'Customer PO',
        'Delivered': 'Delivered',
        'Est. Delivery': 'Est. Delivery',
        'Hide tracking': 'Hide tracking',
        'My last parts orders': 'My last parts orders',
        'No orders': 'No orders',
        'Note: this order will be delivered in multiple shipments.': 'Note: This order will be delivered in multiple shipments.',
        'Order number': 'Order number',
        'Order received': 'Order received',
        'Processing': 'Processing',
        'Shipment': 'Shipment',
        'Shipped': 'Shipped',
        'Tracking': 'Tracking',
        'View tracking': 'View tracking',
    },
    NotFound: {
        'Go to home page': 'Go to home page',
        'Page not found': 'Page not found',
        'The page may have been moved or you may not have the necessary rights to access this page': 'The page may have been moved or you may not have the necessary rights to access this page',
    },
    PaginationTable: {
        "itemsCurrentlyShownText": "{{shownItemAmount}} of {{maxItemAmount}} shown"
    },
    PreventiveMaintenanceCompletion: {
        'Completed': 'Completed',
        'completed in': 'completed in',
        'Count': 'Count',
        'No data for': 'No data for',
        'Not completed': 'Not completed',
        'Percentage': 'Percentage',
        'Planned': 'Planned',
        'tasks': 'tasks',
    },
    ResponseTime: {
        'Action started': 'Action started',
        'avg. response time': 'avg. response time',
        'Call started': 'Call started',
        'in': 'in',
        'Operation': 'Operation',
        'Response time': 'Response time',
    },
    SessionExpirationModal: {
        'Log in again': 'Log in again',
        'You have been logged out due to inactivity': 'You have been logged out due to inactivity',
        'Your session has expired': 'Your session has expired',
    },
    SiteSelector: {
        'Customer': 'Customer',
        'No sites with equipment': 'No sites with equipment',
        'Select a customer': 'Select a customer',
        'Selected': 'Selected',
        'Show all sites': 'Show all sites',
        'Showing all sites': 'Showing all sites',
    },
    TimeUnitLinks: {
        'Show': 'Show',
    },
    Tooltips: {
        'Content': 'Content',
        'Recent reports': 'Recent reports',
    },
};
