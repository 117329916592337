import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import minBy from 'lodash/minBy';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContractType,
    Equipment,
    EquipmentContract,
    MaintenancePlan,
    MaintenanceStatus,
    RunningHours,
} from '../../../interfaces/customer-api';
import InsightContract from '../../assets/contract-icons/insight.svg';
import LeasingContract from '../../assets/contract-icons/leasing.svg';
import MaintenanceContract from '../../assets/contract-icons/maintenance.svg';
import ShortTermRentalContract from '../../assets/contract-icons/short-term-rental.svg';
import Called from '../../assets/maintenance-status-icons/called.svg';
import Completed from '../../assets/maintenance-status-icons/completed.svg';
import Declined from '../../assets/maintenance-status-icons/declined.svg';
import Ordered from '../../assets/maintenance-status-icons/ordered.svg';
import Scheduled from '../../assets/maintenance-status-icons/scheduled.svg';
import Unknown from '../../assets/maintenance-status-icons/unknown.svg';
import { useAsyncData } from '../../hooks';
import { useActions, useEffects, useOvermindState } from '../../state';
import { cns, formatDate, isFailed } from '../../utils';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { Spinner } from '../Spinner/Spinner';
import { Tooltip } from '../Tooltip/Tooltip';
import css from './MyEquipmentItem.module.scss';

interface MaintenanceStatusIconProps {
    status: MaintenanceStatus;
}

const MaintenanceStatusIcon: React.FC<MaintenanceStatusIconProps> = ({ status }: MaintenanceStatusIconProps) => {
    const { t } = useTranslation('MyEquipment');

    let IconComponent = Unknown;

    switch (status) {
        case MaintenanceStatus.CALLED:
            IconComponent = Called;
            break;
        case MaintenanceStatus.DECLINED:
            IconComponent = Declined;
            break;
        case MaintenanceStatus.ORDERED:
            IconComponent = Ordered;
            break;
        case MaintenanceStatus.SCHEDULED:
            IconComponent = Scheduled;
            break;
        case MaintenanceStatus.COMPLETED:
            IconComponent = Completed;
            break;
    }

    return (
        <Tooltip title={t<string>(status)} placement="top">
            <span>
                <IconComponent className={cns(css.statusIcon, css[status])} />
            </span>
        </Tooltip>
    );
};

function getMaintenancePlanClassNameByStatus(status: MaintenanceStatus) {
    switch (status) {
        case 'completed':
            return cns(css.maintenancePlan, css.past);
        default:
            return css.maintenancePlan;
    }
}

function findNextMaintenancePlanIndex(maintenancePlans: MaintenancePlan[]) {
    const startOfToday = DateTime.local().startOf('day');

    // Note: we assume that plans are in descending order by date
    const firstPlan = maintenancePlans[0];
    const allPlansInPast = firstPlan && DateTime.fromISO(firstPlan.date).startOf('day') <= startOfToday;
    if (allPlansInPast) {
        return 0;
    }

    const firstInFuture = minBy(
        maintenancePlans
            .map(({ date }, index) => {
                const startOfToday = DateTime.local().startOf('day');
                const dateObject = DateTime.fromISO(date);
                const diff = dateObject.diff(startOfToday, 'days').days;
                return { diff, index };
            })
            .filter((plan) => plan.diff > 0),
        'diff',
    );
    return firstInFuture ? firstInFuture.index + 1 : undefined;
}

const getScheduleItemKey = (plan: MaintenancePlan, index: number) => `${plan.equipment.equipmentNumber}-${index}`;

interface MaintenanceScheduleItemProps {
    id: string;
    plan: MaintenancePlan;
    t: TFunction;
    isClosestToToday: boolean;
    containerElement: HTMLDivElement | null;
}

const MaintenanceScheduleItem: React.FC<MaintenanceScheduleItemProps> = (props: MaintenanceScheduleItemProps) => {
    const { id, plan, t, isClosestToToday, containerElement } = props;

    const dateObject = DateTime.fromISO(plan.date);
    const date = dateObject ? formatDate(dateObject) : null;

    const className = isClosestToToday
        ? cns(getMaintenancePlanClassNameByStatus(plan.status), css.today)
        : getMaintenancePlanClassNameByStatus(plan.status);

    const scheduleElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isClosestToToday && scheduleElement && scheduleElement.current && containerElement) {
            // Get offsetTop within the container
            const offsetTop = scheduleElement.current.offsetTop - containerElement.offsetTop;

            // Center the schedule element in the container
            const scrollTop = offsetTop - containerElement.offsetHeight / 2 + scheduleElement.current.offsetHeight;

            containerElement.scrollTop = scrollTop;
        }
    }, [isClosestToToday, containerElement]);

    return (
        <div className={className} id={id} ref={scheduleElement}>
            {isClosestToToday && (
                <span className={css.todaySeparator}>
                    <span>{t('Today')}</span>
                </span>
            )}
            <MaintenanceStatusIcon status={plan.status} />
            <div className={css.details}>
                <span className={css.description}>{plan.description}</span>
                {date && <span className={cns(css.date, 'scheduled-date')}>{date}</span>}
            </div>
        </div>
    );
};

const EquipmentMaintenanceSchedule: React.FC = () => {
    const { t } = useTranslation('MyEquipmentItem');
    const { api } = useEffects();

    const { selectedEquipmentId, selectedCustomerNumber } = useOvermindState();

    const loadPlans = () =>
        api
            .loadMaintenancePlansForEquipment(selectedCustomerNumber, selectedEquipmentId)
            .then((plans) => orderBy(plans, 'date', 'desc'));

    const [maintenancePlans, isLoading] = useAsyncData<MaintenancePlan[]>(loadPlans, []);

    const containerRef = useRef<HTMLDivElement>(null);

    const closestToTodayIndex = findNextMaintenancePlanIndex(maintenancePlans);

    if (selectedEquipmentId == null || selectedCustomerNumber == null) {
        return null;
    }
    return (
        <>
            <h3>{t('Maintenance schedule')}</h3>
            <div className={css.maintenanceSchedule} ref={containerRef}>
                {isLoading ? (
                    <Spinner size={3} />
                ) : maintenancePlans.length ? (
                    <div className={css.maintenanceScheduleItems} id="maintenance-schedule-items">
                        {maintenancePlans.map((plan, index) => {
                            const key = getScheduleItemKey(plan, index);
                            return (
                                <MaintenanceScheduleItem
                                    key={key}
                                    id={key}
                                    plan={plan}
                                    t={t}
                                    isClosestToToday={closestToTodayIndex === index}
                                    containerElement={containerRef.current}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <p>{t('No data')}</p>
                )}
            </div>
        </>
    );
};

const getContractIcon = (type: ContractType) => {
    switch (type) {
        case 'leasing':
            return <LeasingContract />;
        case 'maintenance':
        case 'on-demand-maintenance':
            return <MaintenanceContract />;
        case 'rental-short-term':
            return <ShortTermRentalContract />;
        case 'insight':
            return <InsightContract />;
        default:
            return null;
    }
};

interface ContractsProps {
    contracts: EquipmentContract[];
}

const Contracts: React.FC<ContractsProps> = ({ contracts }: ContractsProps) => {
    const { t } = useTranslation('MyEquipmentItem');

    return (
        <>
            <h3>{t('Contracts')}</h3>
            {contracts.map((contract, index) => {
                const startDate = DateTime.fromFormat(contract.contract.contract_start, 'yyyy-MM-dd');
                const endDate = DateTime.fromFormat(contract.contract.contract_end, 'yyyy-MM-dd');

                return (
                    <div key={index} className={css.contract}>
                        {getContractIcon(contract.type)}
                        <h4>{t(contract.type)}</h4>
                        <div>
                            <span className={css.label}>{t('Contract started')}:</span>
                            <span>{formatDate(startDate)}</span>
                        </div>
                        <div>
                            <span className={css.label}>{t('Contract ends')}:</span>
                            <span>{formatDate(endDate)}</span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

interface EquipmentDetailsProps {
    equipmentId: string;
    selectedCustomerNumber: string;
    selectedEquipment: Equipment;
    siteName: string;
    isRunningHoursLoading: boolean;
    runningHours?: RunningHours;
}

export const EquipmentDetails: React.FC<EquipmentDetailsProps> = ({
    equipmentId,
    selectedCustomerNumber,
    selectedEquipment,
    siteName,
    isRunningHoursLoading,
    runningHours,
}: EquipmentDetailsProps) => {
    const { t } = useTranslation('MyEquipmentItem');

    if (equipmentId == null || selectedCustomerNumber == null || selectedEquipment == null) {
        return <p className={css.myEquipmentItem}>{t('No equipment found')}</p>;
    }
    return (
        <>
            <h3>{t('Equipment details')}</h3>
            <dl>
                <dt>{t('Serial number')}</dt>
                <dd id="serial-number">{selectedEquipment.serial_number}</dd>
                <dt>{t('Type')}</dt>
                <dd id="type">{selectedEquipment.type ? selectedEquipment.type : t('Other')}</dd>
                <dt>{t('Site')}</dt>
                <dd id="site">{siteName}</dd>
                <dt>{t('Model')}</dt>
                <dd id="model">{selectedEquipment.manufacturer_model_number}</dd>
                <dt>{t('Manufacturing year')}</dt>
                <dd>{selectedEquipment?.manufacturing_year}</dd>
                <dt>{t('Running hours')}</dt>
                <dd>
                    {isRunningHoursLoading ? (
                        <Spinner size={1} />
                    ) : runningHours?.runningHours !== undefined ? (
                        `${runningHours.runningHours} h`
                    ) : (
                        t('No data')
                    )}
                </dd>
            </dl>
        </>
    );
};
interface MyEquipmentItemProps extends RouteComponentProps {
    /** URL parameter set by router `/my-equipment/:equipmentId` */
    equipmentId?: string;
}

export const MyEquipmentItem: React.FC<MyEquipmentItemProps> = (props: MyEquipmentItemProps) => {
    const { equipmentId } = props;
    const { loadingStates, selectedCustomerNumber, selectedEquipment, sitesByShipTo } = useOvermindState();
    const { t } = useTranslation('MyEquipmentItem');
    const { getEquipment, setSelectedEquipmentId } = useActions();
    const { api } = useEffects();

    const loadRunningHours = () => api.loadRunningHours(selectedCustomerNumber, selectedEquipment?.serial_number || '');
    const [runningHours, isRunningHoursLoading] = useAsyncData<RunningHours>(
        loadRunningHours,
        {
            runningHours: undefined,
        },
        [selectedEquipment?.serial_number, selectedCustomerNumber],
    );
    // Synchronize the selected equipment ID from the route to the store
    useEffect(() => {
        if (equipmentId) {
            setSelectedEquipmentId(equipmentId);
        }
        return () => {
            // Reset on unmount
            setSelectedEquipmentId(null);
        };
    }, [equipmentId, setSelectedEquipmentId]);

    if (isFailed(loadingStates.equipment)) {
        return <LoadingFailure translationKey="Loading equipment failed" retry={getEquipment} />;
    }

    if (equipmentId == null || selectedCustomerNumber == null || selectedEquipment == null) {
        return <p className={css.myEquipmentItem}>{t('No equipment found')}</p>;
    }

    const shipTo = selectedEquipment.business_partner_sh;
    const equipmentSite = sitesByShipTo[shipTo];
    const siteName = equipmentSite && equipmentSite.name ? equipmentSite.name : shipTo;

    return (
        <div className={css.myEquipmentItem} id="my-equipment-item">
            <div className={css.columns}>
                <div className={css.column}>
                    <EquipmentDetails
                        equipmentId={equipmentId}
                        selectedCustomerNumber={selectedCustomerNumber}
                        selectedEquipment={selectedEquipment}
                        siteName={siteName}
                        isRunningHoursLoading={isRunningHoursLoading}
                        runningHours={runningHours}
                    />
                </div>
                <div className={css.column}>
                    {selectedEquipment.contracts && selectedEquipment.contracts.length > 0 && (
                        <Contracts contracts={selectedEquipment.contracts} />
                    )}
                </div>
                <div className={css.column}>
                    <EquipmentMaintenanceSchedule />
                </div>
            </div>
        </div>
    );
};
